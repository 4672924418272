import React from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Heading,
  Text,
  TextLink,
  Button,
  base,
} from '@feast-it/pesto';

import Mask from '../../components/Mask';
import { CONTACT_NUMBER, CONTACT_HOURS } from '../../constants/contact';
import { useBreakpoint } from '../../hooks/media';
import { openLiveChat } from '../../modules/liveChat';

import * as Styled from './styles';

const ContactUs = ({ image }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const isDesktop = useBreakpoint(base.breakpoints.l);

  return (
    <Box
      border={{ l: '1px solid' }}
      borderColor={{ l: 'blueLight' }}
      borderRadius={{ l: 3 }}
      overflow="hidden"
      ref={ref}
    >
      <Grid
        gridTemplateColumns={{ l: 'repeat(12, 1fr)' }}
        gridGap={{ l: 'gutter.m' }}
      >
        <GridItem gridColumn={{ l: '1 / span 5' }}>
          {isDesktop ? (
            <Mask
              position="right"
              size="auto 100%"
              height="100%"
              src="/images/generic/mask-4.svg"
              display={{ _: 'none', l: 'block' }}
            >
              <Styled.DesktopImage>
                <Image
                  src={image}
                  width={532}
                  height={362}
                  inView={inView}
                  imgix
                />
              </Styled.DesktopImage>
            </Mask>
          ) : (
            <Mask
              src="/images/generic/mask-5.svg"
              display={{ _: 'block', l: 'none' }}
            >
              <Image
                src={image}
                width={375}
                height={224}
                inView={inView}
                imgix
              />
            </Mask>
          )}
        </GridItem>

        <GridItem
          gridColumn={{ l: '6 / span 7' }}
          py={{ _: 4, l: 9 }}
          px={{ _: 'gutter._', s: 'gutter.s', m: 'gutter.m' }}
          pl={{ l: 0 }}
        >
          <Flex flexDirection="column" height="100%">
            <Box flex="1">
              <Heading
                as="h2"
                variant={{
                  _: 'headingExtraLargeMobile',
                  m: 'headingExtraLarge',
                }}
                color="blueDark"
              >
                Chat to a Feast It Event Expert
              </Heading>

              <Text
                fontFamily="heading"
                variant={{ _: 'body', m: 'large' }}
                color="blueDark"
                mt={2}
              >
                Got questions? We’re here to help.
              </Text>
            </Box>

            <Grid
              gridTemplateColumns={{ l: '1fr 1fr' }}
              gridColumnGap="gutter.m"
              mt={6}
            >
              <GridItem
                borderBottom={{ _: '1px solid', l: 'none' }}
                borderBottomColor="blueLight"
                pb={{ _: 4, l: 0 }}
                mb={{ _: 4, l: 0 }}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  height="100%"
                >
                  <Heading as="h3" variant="headingSmall" color="blueDark">
                    Chat to our team online
                  </Heading>

                  <Button
                    variant="primaryOutline"
                    icon="Message"
                    iconSize={20}
                    onClick={openLiveChat}
                    mt={4}
                    mb={2}
                  >
                    Live chat
                  </Button>

                  <Text fontFamily="heading" variant="small" color="greyDark">
                    Our team can help with general questions, existing bookings
                    or supplier queries through live chat.
                  </Text>
                </Flex>
              </GridItem>

              <GridItem
                borderBottom={{ _: '1px solid', m: 'none' }}
                borderBottomColor="blueLight"
                pb={{ _: 4, l: 0 }}
                mb={{ _: 4, l: 0 }}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  height="100%"
                >
                  <Heading as="h3" variant="headingSmall" color="blueDark">
                    Give us a call
                  </Heading>

                  <Heading
                    as="p"
                    variant="headingLargeMobile"
                    color="primary"
                    mt={4}
                    mb={2}
                  >
                    <TextLink href={`tel:${CONTACT_NUMBER}`}>
                      {CONTACT_NUMBER}
                    </TextLink>
                  </Heading>

                  <Text fontFamily="heading" variant="small" color="greyDark">
                    {CONTACT_HOURS.map(line => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

ContactUs.propTypes = {
  image: PropTypes.string.isRequired,
};

export default ContactUs;

import React from 'react';
import { Box, Container } from '@feast-it/pesto';

import ContactUs from '../../components/ContactUs';

const ContactUsWrapper = rawData => {
  const image = rawData?.items?.[0]?.picture?.url;

  return (
    <Box as="section" py={{ l: 10 }} px={{ l: 'gutter.m' }}>
      <Container>
        <ContactUs image={image} />
      </Container>
    </Box>
  );
};

export default ContactUsWrapper;

import styled from '@emotion/styled/macro';
import { display, space, layout, system } from 'styled-system';

export const Mask = styled.div`
  ${display}
  ${space}
  ${layout}
  mask-size: ${({ size }) => size};
  mask-position: ${({ position }) => position};
  mask-repeat: no-repeat;

  ${system({
    src: {
      property: 'maskImage',
      transform: src => `url(${src})`,
    },
  })}
`;

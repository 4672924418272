import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const Mask = ({ children, size, position, ...props }) => {
  return (
    <Styled.Mask size={size} position={position} {...props}>
      {children}
    </Styled.Mask>
  );
};

Mask.defaultProps = {
  size: 'contain',
  position: 'center',
};

Mask.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Mask;

export const EMAIL_CONTACT = 'contact@feast-it.com';

export const EMAIL_PARTNERS = 'partners@feast-it.com';

export const EMAIL_SUPPLIER = 'vendor@feast-it.com';

export const EMAIL_PRESS = 'press@feast-it.com';

export const EMAIL_BOOKING = 'booking@feast-it.com';

export const CONTACT_NUMBER = '+44 (0) 203 905 7439';

export const CONTACT_HOURS = [
  '9:30am – 5:30pm Monday to Friday',
  '(except bank holidays)',
];
